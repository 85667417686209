<template>
    <div
        class="social-share"
    >
        <h3 class="social-share__title">Delen</h3>
        <div class="social-share__items">
            <BaseButton
                v-for="social in socials"
                :key="social"
                class="social-share__item base-button--secondary"
                @click="share(social)"
            >
                <BaseIcon
                    :icon="social"
                />
            </BaseButton>
        </div>
    </div>
</template>

<script setup>
import { useGtm } from '@gtm-support/vue-gtm';

const socials = [
    'facebook',
    'x',
    'linkedin'
];

const share = (social) => {
    useGtm()?.trackEvent({
        event: 'social_share_click',
        medium: social
    });

    switch (social) {
    case 'facebook':
        window.open(
            `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`,
            'Delen'
        );
        break;
    case 'x':
        window.open(
            `https://twitter.com/intent/tweet?url=${encodeURIComponent(window.location.href)}`,
            'Delen'
        );
        break;
    case 'linkedin':
        window.open(
            `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(window.location.href)}`,
            'Delen'
        );
        break;
    }
};
</script>

<style lang="less" src="./SocialShare.less"></style>
