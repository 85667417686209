<template>
    <div
        v-if="supporter && supporter.title"
        class="supporter"
        :data-is-expert="isExpert"
        :data-image-count="images.length"
    >
        <div
            v-if="images && images.length"
            class="supporter__images"
        >
            <div
                v-for="item in images.slice(0, 3)"
                :key="item.id"
                class="supporter__image"
            >
                <BaseImage
                    :image="item"
                    :alt="item.alt"
                    :width="item.width"
                    :height="item.height"
                    sizes="80px"
                />
            </div>

            <div
                v-if="images.length > 3"
                class="supporter__image supporter__image--more"
            >
                <div>
                    +{{ images.length - 3 }}
                </div>
            </div>
        </div>

        <div
            class="supporter__body"
        >
            <h3
                v-if="isExpert && supporter.title"
                class="supporter__title"
            >
                {{ supporter.title }}
            </h3>

            <div
                v-if="isExpert && supporter.role"
                class="supporter__role"
            >
                {{ supporter.role }}
            </div>
        </div>

        <div
            v-if="supporter.email || supporter.phone"
            class="supporter__links"
        >
            <BaseButton
                v-if="supporter.email"
                element="a"
                icon="mail"
                class="base-button--transparent-dark base-button--icon-only"
                :href="`mailto:${supporter.email}`"
            />
            <BaseButton
                v-if="supporter.phone"
                element="a"
                icon="phone"
                class="base-button--transparent-dark base-button--icon-only"
                :href="`tel:${supporter.phone}`"
            />
        </div>
    </div>
</template>

<script setup>
import BaseImage from '@/components/BaseImage/BaseImage.vue';
import { toRefs } from 'vue';

const props = defineProps({
    supporter: {
        type: Object,
        required: true,
        default: null
    },
    isExpert: {
        type: Boolean,
        default: true
    }
});

const { image, experts } = toRefs(props.supporter);

const images = computed(() => {
    // If the supporter is an expert, we want to show the expert image
    if (props.isExpert) {
        return image?.value;
    }

    // Else we want to show the images of the department experts
    return experts?.value?.map((expert) => expert.image[0]).filter((item) => item) || [];
});
</script>

<style lang="less" src="./SupportCard.less"></style>
